import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import GatsbyImage from "gatsby-image"

const SingleCard = ({ text = "text", index, fluid = null, handle }) => {
  // const fluid = defaultImage.childImageSharp.fluid

  return (
    <div className="mt-5">
      <BackgroundImage fluid={fluid}>
        <Link
          to={`/${handle}`}
          title={text}
          className={` h-32   w-72  xl:w-64  xl:h-64  ${
            index % 4 === 1
              ? `bg-blue-400`
              : index % 3
              ? `bg-green-500`
              : index % 2
              ? `bg-yellow-500`
              : `bg-red-500`
          } bg-opacity-80 text-4xl uppercase font-bold text-white grid items-center justify-center group`}
        >
          <h2 className="transition duration-200 ease-in-out transform group-hover:scale-110">
            {text}
          </h2>
        </Link>
      </BackgroundImage>
    </div>
  )
}

const HighlightCards = () => {
  const { aboutUsImage, communityImage, lifestyleImage, healthImage } =
    useStaticQuery(graphql`
      query HighlightCardsQuery {
        aboutUsImage: file(
          relativePath: { eq: "index/MenoYaWinInterior.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 500, grayscale: true) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        communityImage: file(relativePath: { eq: "index/Blueberry Bert.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 500, grayscale: true) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        lifestyleImage: file(relativePath: { eq: "index/Ice Fishing.JPG" }) {
          childImageSharp {
            fluid(maxWidth: 500, grayscale: true) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        healthImage: file(relativePath: { eq: "index/Hospital.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 500, grayscale: true) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)

  const cardInfo = [
    {
      text: "About Us",
      fluid: aboutUsImage.childImageSharp.fluid,
      handle: "about",
    },
    {
      text: "News & Events",
      fluid: communityImage.childImageSharp.fluid,
      handle: "news",
    },
    {
      text: "Lifestyle",
      fluid: lifestyleImage.childImageSharp.fluid,
      handle: "lifestyle",
    },
    {
      text: "Health",
      fluid: healthImage.childImageSharp.fluid,
      handle: "opportunities",
    },
  ]
  // const { fluid } = data.aboutUsImage.childImageSharp

  return (
    <div className="flex flex-col items-center justify-center xl:flex-row gap-x-5  -mt-10 mx-4 ">
      {cardInfo.map((card, index) => (
        <SingleCard
          text={card.text}
          index={index}
          fluid={card.fluid}
          handle={card.handle}
        ></SingleCard>
      ))}
    </div>
  )
}

export default HighlightCards
