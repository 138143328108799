import React from "react"
import BlockContentPrimary from "../block content/blockContentPrimary"
import ButtonLink from "../ButtonLink"
import CardContainer from "./CardContainer"

const CardTitle = ({ title = { text: "Card Title", color: null } }) => {
  return (
    <>
      <h3
        className={` text-center md:text-left text-2xl md:text-4xl uppercase tracking-wider ${
          title.color || " text-quaternary "
        } `}
      >
        {title.text}
      </h3>
      <div
        className="bg-fifth w-1/2 justify-self-center md:justify-self-start my-3"
        style={{ height: "2px" }}
      />
    </>
  )
}

export const RoleCard = ({
  title = "title error",
  summary = null,
  subTitle = null,
  handle = null,
  deadline = null,
}) => {
  return (
    <CardContainer
      className="mt-10"
      button={{ text: "Learn More", to: `opportunities/${handle}` }}
    >
      <div className="mb-3 grid">
        <CardTitle title={{ text: title }} />
        {subTitle && <h3 className=" text-center md:text-left ">{subTitle}</h3>}
        <p className="italic text-center md:text-left">
          Deadline: {`${deadline || "Open until filled"}`}
        </p>
      </div>
      <section className=" md:pl-5">{summary}</section>
    </CardContainer>
  )
}

const MainCard = ({
  id = "",
  className,
  title = { text: "Title", color: null },
  children = (
    <div>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque luctus
      sapien diam, vel faucibus justo efficitur sit amet. Etiam fermentum sem
      vitae neque ullamcorper sagittis. Praesent nec ante et mi interdum luctus
      at a lorem. Aliquam pharetra, leo nec dictum fermentum, erat mi faucibus
      est, ut lobortis risus elit sit amet quam. Vestibulum et elit non odio
      elementum ornare. Morbi pellentesque mi aliquam mauris iaculis dapibus.
      Aenean pulvinar felis eu felis suscipit, ut cursus turpis blandit.{" "}
    </div>
  ),
  headerTop = "top-60",
  button = null,
}) => {
  return (
    <CardContainer className={className} id={id} button={button}>
      <div
        className={`md:absolute left-0 ${headerTop} md:transform md:-rotate-90 md:origin-top-left  md:-ml-20  grid mb-5 md:mb-0 `}
      >
        <h3
          className={` text-center md:text-left text-4xl uppercase tracking-wider ${
            title.color || "text-secondary "
          } `}
        >
          {title.text}
        </h3>
        <div
          className="bg-fifth w-1/2 justify-self-center md:justify-self-end my-3"
          style={{ height: "2px" }}
        />
      </div>
      <>{children}</>
    </CardContainer>
  )
}

export default MainCard
