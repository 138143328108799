import React from "react"
import SEO from "../components/SEO"
import { graphql } from "gatsby"
import { getFluidGatsbyImage } from "gatsby-source-sanity"

import Layout from "../components/layout"
import HeroContainer from "../components/heroContainer"

import HighlightCards from "../components/index/HighlightCards"
import MainCard from "../components/card/MainCard"
import RenderNewsCards from "../components/news/RenderNewsCards"
import ButtonLink from "../components/ButtonLink"
import BlockContentPrimary from "../components/block content/blockContentPrimary"
import { sanityConfig } from "../sanityConfig"
import { AiOutlineArrowRight } from "react-icons/ai"
import VideoPlayer from "../components/video/VideoPlayer"
import BackgroundContainer from "../components/containers/BackgroundContainer"

const IndexPage = ({ data }) => {
  const { pageContent } = data
  const heroFluid = data.heroImage.childImageSharp.fluid
  const secondFluid = data.secondBanner.childImageSharp.fluid
  const thirdFluid = data.thirdBanner.childImageSharp.fluid
  // const newsPosts = flattenEdgesArray(data.newsPosts)

  const fluidBanner =
    getFluidGatsbyImage(
      pageContent?._rawHeaderImage?.asset?._id,
      { maxWidth: 1200 },
      sanityConfig
    ) || data.heroImage.childImageSharp.fluid

  const containerTwoCardProps = [
    {
      button: {
        text: "Read More",
        className: "text-black bg-tertiary",
      },
      container: {
        className: "text-white",
      },
      divider: {
        className: "bg-tertiary",
      },
      header: {
        text: "Welcome",
        hide: false,
      },
      text: [
        'When choosing to work in Northwestern Ontario, you are not only choosing your employer and career opportunities, you are choosing a way of life. If adventure, solitude in nature and the great outdoors at your fingertips are attractive to you, this is the place for you and your family. We invite you to explore our website and find out all that Sioux Lookout and area has to offer you! When choosing to work in Northwestern Ontario, you are not only choosing your employer and career opportunities, you are choosing a way of life. If"',
      ],
    },
    {
      button: {
        text: "Read More",
        className: "text-black bg-tertiary",
      },
      container: {
        className: "text-white",
      },
      divider: {
        className: "bg-tertiary",
      },
      header: {
        text: "Welcome",
        hide: true,
      },
      text: [
        'When choosing to work in Northwestern Ontario, you are not only choosing your employer and career opportunities, you are choosing a way of life. If adventure, solitude in nature and the great outdoors at your fingertips are attractive to you, this is the place for you and your family. We invite you to explore our website and find out all that Sioux Lookout and area has to offer you! When choosing to work in Northwestern Ontario, you are not only choosing your employer and career opportunities, you are choosing a way of life. If"',
      ],
    },
  ]

  const heroProps = {
    fluid: fluidBanner,
    text: null,
    gradient: true,
  }

  return (
    <Layout>
      <SEO title="Home" />
      <HeroContainer props={heroProps} className=" " />

      <BackgroundContainer bgFluid={secondFluid}>
        <HighlightCards />

        <MainCard
          className="text-secondary mt-10 w-full  "
          title={{ text: "Welcome" }}
          headerTop="top-40"
          button={{ text: "Learn More", to: "about" }}
        >
          {pageContent?._rawWelcomeSection ? (
            <BlockContentPrimary blockData={pageContent._rawWelcomeSection} />
          ) : (
            <p>Error: Missing Information</p>
          )}
        </MainCard>
        <div className="w-full flex flex-col items-center justify-center my-5 ">
          <VideoPlayer
            url="https://www.youtube.com/embed/rs-SUb2vWOU"
            caption="Video created by Chris Aitken for SLRPSI, 2021"
          />
        </div>
      </BackgroundContainer>

      <BackgroundContainer bgFluid={thirdFluid}>
        <div className="  grid justify-items-stretch relative justify-self-stretch bg-secondary bg-opacity-0">
          <MainCard
            className="  text-secondary my-20  w-full relative justify-self-stretch grid justify-items-center "
            title={{ text: "Latest News", color: "text-secondary" }}
            headerTop="top-52"
          >
            <RenderNewsCards
              newsCardsData={data.newsPosts}
              className="bg-white  bg-opacity-100 shadow-md"
              maxCards={2}
            />
            <ButtonLink
              to="/news"
              text={
                <span className="flex items-center">
                  News Page <AiOutlineArrowRight className="ml-1" />
                </span>
              }
              className="text-xl px-3 py-1  bg-fifth  text-white hover:bg-white hover:text-fifth border-2 border-fifth  font-semibold justify-self-center uppercase tracking-wide"
            />
          </MainCard>
        </div>
      </BackgroundContainer>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    pageContent: sanityHomePage {
      title
      _rawWelcomeSection(resolveReferences: { maxDepth: 10 })
      _rawHeaderImage(resolveReferences: { maxDepth: 10 })
    }
    heroImage: file(relativePath: { eq: "home_hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    secondBanner: file(relativePath: { eq: "second_banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    thirdBanner: file(
      relativePath: { eq: "kristjan-sverrisson-FjVBKyP95Ik-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    newsPosts: allSanityBlogPost {
      edges {
        node {
          ...NewsCard
        }
      }
    }
  }
`
