import React, { useState } from "react"
import {
  flattenEdgesArray,
  addDateToSortByField,
  sortPostByDate,
} from "../../helperFunctions"
import DisplayCard from "../DisplayCard"

// Input News Cards data from graphql with edges and nodes intact
// Param "maxCards" is optional, and will limit the number of cards shown

const RenderNewsCards = ({
  newsCardsData = { edges: [] },
  className,
  lightColor = false,
  maxCards,
}) => {
  const [numCards, setNumCards] = useState(maxCards || 3)

  function addMoreCards() {
    return setNumCards(numCards + 5)
  }

  const flatArray = flattenEdgesArray(newsCardsData)
  if (flatArray) {
    // Create a deep copy of the array without references using JSON methods
    // https://www.freecodecamp.org/news/how-to-clone-an-array-in-javascript-1d3183468f6a/
    const flatArrayCopy = JSON.parse(JSON.stringify(flatArray))
    // Add "date to sort by" and "date string" fields
    const arrayWithNewDateFields = addDateToSortByField(flatArrayCopy)

    const sortedPosts = sortPostByDate(arrayWithNewDateFields)
    const slicedArray = sortedPosts.slice(0, numCards)

    return (
      <div className={`  grid w-full gap-y-10 `}>
        {slicedArray.map(
          ({
            title,
            summary,
            slug,
            id,
            dateString,
            author,
            rawContent,
            featureImage,
          }) => {
            const newsCardProps = {
              id,
              title,
              author,
              rawContent,
              text: summary,
              handle: `/news/${slug.current}`,
              date: dateString,
              fluid: featureImage?.asset?.fluid || null,
            }
            return (
              <DisplayCard
                key={`newsCard-${id}`}
                className={`${
                  lightColor
                    ? "text-white bg-white bg-opacity-20"
                    : "text-secondary bg-black bg-opacity-10"
                }  mb-10  px-6 py-4 rounded   ${className}`}
                {...newsCardProps}
              />
            )
          }
        )}
        {/* Only show button when there are more cards to show, and a value for "maxCards" has not been set */}
        {!maxCards && numCards > 1 && numCards <= slicedArray.length && (
          <button
            onClick={() => addMoreCards()}
            className="text-xl px-3 py-1  bg-fifth  text-white hover:bg-white hover:text-fifth border-2 border-fifth  font-semibold justify-self-center transition duration-100 ease-in-out transform hover:scale-105"
          >
            Show More Posts
          </button>
        )}
      </div>
    )
  }
  return (
    <div
      className={`p-10 grid w-full justify-items-center  ${
        lightColor ? "text-white" : "text-secondary"
      } `}
    >
      <h2 className="mt-10">Sorry, no posts yet. Check back again soon!</h2>
    </div>
  )
}

export default RenderNewsCards
