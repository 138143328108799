import React from "react"
import ButtonLink from "../ButtonLink"

const CardContainer = ({ children, button = null, className = null, id }) => {
  return (
    <div
      id={id}
      className={` my-0 mx-auto box-border grid justify-items-center  md:pl-20 ${className} `}
    >
      <div
        className=" grid min-h-xs relative inline-block w-full max-w-4xl content-start "
        // style={{ borderLeft: "50px solid red" }}
      >
        {children}
        {button && (
          <ButtonLink
            className="  justify-self-center md:justify-self-start uppercase tracking-widest mt-5"
            text={button.text}
            to={`/${button.to}`}
          />
        )}
      </div>
    </div>
  )
}

export default CardContainer
