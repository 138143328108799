import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"

import { BlockTextToPlainText } from "../helperFunctions"

import ButtonLink from "./ButtonLink"

const TitleRowItem = ({ children, className }) => (
  <div className={`md:mx-3 first:ml-0  ${className}`}>{children}</div>
)

const DisplayCard = ({
  fluid = null,
  altText = "SLRPSI Logo",
  title = "TITLE",
  date = "DATE NOT PROVIDED",
  time = null,
  summary = "",
  author = null,
  location = null,
  handle = "#",
  className = "",
  rawContent = null,
  buttonText = null,
}) => {
  const data = useStaticQuery(graphql`
    query DisplayCardQuery {
      defaultBlogImage: file(relativePath: { eq: "JustLogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const displayImage = fluid || data.defaultBlogImage.childImageSharp.fluid

  // const plainTextElement = <BlockTextToPlainText/>

  return (
    <div
      className={` text-black  flex flex-col items-center md:flex-row  mx-2 md:min-w-mdCard lg:min-w-lgCard ${className} `}
    >
      <GatsbyImage
        className="w-52 h-52 min-w-52 md:mr-10 mb-5 md:mb-0"
        fluid={displayImage}
        alt={altText}
      />

      <div className="grid blog-grid content-start max-w-2xl w-full">
        <div className="lg:flex text-center lg:text-left ">
          {title && (
            <TitleRowItem className="font-bold text-2xl ml-0  lg:max-w-sm">
              {title}
            </TitleRowItem>
          )}
          {date && (
            <>
              <TitleRowItem className="hidden lg:inline-block">|</TitleRowItem>
              <TitleRowItem className=" text-quaternary text-center">
                {date}
              </TitleRowItem>
            </>
          )}
          {time && (
            <>
              <TitleRowItem className="hidden lg:inline-block">|</TitleRowItem>
              <TitleRowItem className=" text-quaternary text-center">
                {time}
              </TitleRowItem>
            </>
          )}
        </div>
        <div className="lg:flex text-center lg:text-left">
          {author && (
            <>
              <div>
                <span className="font-bold">Author:</span>&nbsp;
                <span className="italic">{author}</span>
              </div>
            </>
          )}
          {location && (
            <>
              <div>
                <span className="italic text-gray-400">{location}</span>
              </div>
            </>
          )}
        </div>
        <div className="relative mt-3 min-h-150px  max-h-56 overflow-y-hidden ">
          {/* <div className="absolute top-0 right-0 bottom-0 left-0 bg-gradient-to-b from-transparent to-whiteTransparent"></div> */}
          {summary ? (
            <p className="">{summary}</p>
          ) : rawContent ? (
            <BlockTextToPlainText blocks={rawContent} />
          ) : null}
          {/* <pre>{plainTextMainContent}</pre> */}

          <div className="absolute top-0 left-0 right-0 bottom-0   bg-gradient-to-t from-white z-20"></div>
        </div>
        <ButtonLink
          to={handle}
          className=" justify-self-end mt-3"
          text={`${buttonText || "Read More"}`}
        />
      </div>
    </div>
  )
}

export const query = graphql`
  fragment NewsCard on SanityBlogPost {
    id
    title
    author
    summary
    featureImage {
      asset {
        fluid(maxWidth: 250) {
          ...GatsbySanityImageFluid
        }
      }
    }
    publishedDate
    rawContent: _rawPostContent(resolveReferences: { maxDepth: 10 })
    _createdAt
    slug {
      current
    }
  }
  fragment EducationEventCard on SanityEducationEvent {
    id
    title
    eventLocation
    slug {
      current
    }
    eventDateTime
    isPastEvent
    summary
    rawContent: _rawEventMainContent(resolveReferences: { maxDepth: 10 })
    bannerHeroImage {
      altText
      asset {
        fluid(maxWidth: 250) {
          ...GatsbySanityImageFluid
        }
      }
    }
  }
`

export default DisplayCard
